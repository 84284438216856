<template>
  <div :class="{ 'small-pagination': small, }">
    <ul class="pagination" :style="cssStyle">
      <li class="page-item">
        <a class="page-link" @click.prevent="reset()" :class="{ disabled: !hasPrevious || disabled, }">
          <i class="fa fa-backward"></i>
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" @click.prevent="dec()" :class="{ disabled: !hasPrevious || disabled, }">
          <i class="fa fa-chevron-left"></i>
        </a>
      </li>
      <li class="page-item page-item-value">
        <b-input type="number" class="pagination-input" min="1" :max="pagesCount" v-model="page" :disabled="disabled">
        </b-input>
        / {{ pagesCount }}
      </li>
      <li class="page-item">
        <a class="page-link" @click.prevent="inc()" :class="{ disabled: !hasNext || disabled, }">
          <i class="fa fa-chevron-right"></i>
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" @click.prevent="last()" :class="{ disabled: !hasNext || disabled, }">
          <i class="fa fa-forward"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'Pagination',
  props: {
    hasNext: Boolean,
    hasPrevious: Boolean,
    pagesCount: Number,
    selector: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    force: {
      type: Number,
      default: 0,
    },
    cssStyle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      page: 0,
    }
  },
  computed: {
    pageCountNumber() {
      return Math.max(1, +this.pagesCount)
    },
  },
  watch: {
    selector: function() {
      this.page = 1
    },
    force: function() {
      this.page = this.force
    },
    disabled: function() {
    },
    pagesCount: function() {
      if (this.page > this.pagesCount) {
        this.page = this.pagesCount
      }
    },
    page: function(page) {
      if (typeof page === 'string') {
        if (isNaN(page)) {
          this.page = 1
        } else {
          this.page = +page
        }
      } else {
        if ((!page) || (isNaN(page))) {
          this.page = 1
        } else {
          if (+page < 1) {
            // page avant la 1ère
          } else if (+page > this.pagesCount) {
            // plus que la dernière page
          } else {
            if (('' + page) !== this.$route.query.page) {
              this.$router.replace({ query: { ...this.$route.query, page: '' + page, }, })
            }
            this.$emit('change', { page: +page, })
          }
        }
      }
    },
    hasNext: function() {},
    hasPrevious: function() {},
  },
  methods: {
    reset() {
      if (!this.disabled) {
        this.page = 1
      }
    },
    inc() {
      if (!this.disabled) {
        if (this.page < this.pagesCount) {
          this.page += 1
        }
      }
    },
    dec() {
      if (!this.disabled) {
        if (this.page > 1) {
          this.page -= 1
        }
      }
    },
    last() {
      if (!this.disabled) {
        this.page = this.pagesCount
      }
    },
  },
  mounted() {
    let page = this.$route.query.page
    if ((!page) || (isNaN(page))) {
      page = 1
    } else {
      page = Math.max(1, +page)
    }
    if (page !== this.page) {
      this.page = page
    }
  },
}
</script>
<style lang="less">
input.pagination-input {
  width: 60px;
  display: inline-block;
  font-size: 12px;
  padding: 18px 5px;
}
.page-item-value {
  font-size: 0.8em;
  padding: 0 5px;
}
.small-pagination {
  .page-item-value {
    font-size: 0.7em;
    padding: 0 2px;
  }
  a.page-link {
    font-size: 0.9em;
    padding: 5px;
  }
  input.pagination-input {
    width: 50px;
    display: inline-block;
    font-size: 11px;
    padding: 16px 4px;
  }
}
</style>
