<template>
  <div class="notes-list" v-if="canViewNotes">
    <div>
      <page-header title="Notes" icon="fa fa-sticky-note"></page-header>
      <div class="sub-header">
        <b-row>
          <b-col cols="2">
            <b>
              <counter-label :counter="notesCount" label="note"></counter-label>
            </b>
          </b-col>
          <b-col cols="3">
            <b-form-checkbox id="include-archived-notes2" v-model="includeArchives">
              Inclure les archives
            </b-form-checkbox>
          </b-col>
          <b-col cols="3">
            <b-form-checkbox id="viewBody" v-model="viewBody">
              Afficher le contenu
            </b-form-checkbox>
          </b-col>
          <b-col cols="4" class="text-right">
            <pagination
              :pages-count="pagesCount"
              :has-next="hasNext"
              :has-previous="hasPrevious"
              :selector="paginationSelector"
              @change="onPageChanged($event)"
              style="display: inline-block;"
            >
            </pagination>
          </b-col>
        </b-row>
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <note-items
        :items="notes"
        v-if="!isLoading(loadingName)"
        @item-closed="onItemClosed($event)"
        no-edit
        :view-body="viewBody"
      ></note-items>
    </div>
    <confirm-modal
      name="delete-the-note"
      title="Suppression d'une note"
      :text="confirmDeleteText"
      :object="selectedNote"
      @confirmed="onDeleteNote"
    >
    </confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'
import Pagination from '@/components/Controls/Pagination'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import NoteItems from '@/components/Notes/NoteItems.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeNote } from '@/types/notes'

export default {
  name: 'notes',
  mixins: [BackendMixin],
  components: {
    PageHeader,
    LoadingGif,
    NoteItems,
    CounterLabel,
    Pagination,
    ConfirmModal,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'notes-list',
      notes: [],
      page: 1,
      notesCount: 0,
      hasNext: false,
      hasPrevious: false,
      selectedNote: null,
      includeArchives: false,
      viewBody: false,
    }
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.notesCount / 100)
    },
    paginationSelector() {
      return ''
    },
    canViewNotes() {
      return this.hasPerm('notes.view_note')
    },
    canDeleteNote() {
      return this.hasPerm('notes.delete_note')
    },
    confirmDeleteText() {
      if (this.selectedNote) {
        if (this.selectedNote.archived) {
          return 'La note ' + this.selectedNote.title + ' sera définitivement supprimé de millibase'
        } else {
          return 'La note ' + this.selectedNote.title + ' sera archivée'
        }
      }
      return ''
    },
  },
  watch: {
    includeArchives: function() {
      this.loadNotes(this.page)
    },
  },
  mounted() {
    this.loadNotes(1)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onPageChanged(event) {
      this.page = event.page
      this.loadNotes(this.page)
    },
    async loadNotes(page) {
      let url = '/notes/api/notes/?page=' + page
      if (this.includeArchives) {
        url += '&include_archives=1'
      }
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.notesCount = resp.data.count
        this.hasNext = !!resp.data.next
        this.hasPrevious = !!resp.data.previous
        this.notes = resp.data.results.map(elt => makeNote(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    onNoteAdded(note) {
      this.editMode = false
      this.notes = [note].concat(this.notes)
    },
    onItemClosed(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:delete-the-note')
    },
    async onDeleteNote() {
      if (this.selectedNote && this.canDeleteNote) {
        this.startLoading(this.loadingName)
        let url = '/notes/api/notes/' + this.selectedNote.id + '/'
        if (this.selectedNote.archived) {
          url += '?include_archives=1'
        }
        const backendApi = new BackendApi('delete', url)
        try {
          const resp = await backendApi.callApi()
          await this.loadNotes(this.page)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedNote = null
        this.endLoading(this.loadingName)
      }
    },
  },
}
</script>

<style lang="less">
</style>
